const partners = [
  { name: "JPEL Farms", logo: "assets/newlogo2.png" },
  {
    name: "Libin Web Development",
    logo: "assets/yellowkl.png",
    link: "https://libinwebdevelopment.com",
  },
  {
    name: "Pulse Wellness",
    logo: "assets/pulse.webp",
    link: "https://www.pulsewellnessco.com/",
  },
];

export default partners;
