import React from "react";

//styles
import "./Modal.scss";

//react icons
import { IoClose } from "react-icons/io5";

const Modal = ({ setIsOpen, submissionStatus, setSubmissionStatus }) => {
  return (
    <>
      <div className="modal-backdrop" onClick={() => setIsOpen(false)} />
      <div className="centered">
        <div
          className="background-color"
          data-aos="flip-up"
          data-aos-duration="500"
        >
          <div className="modal">
            <div className="modal-header">
              <h5 className="heading">{submissionStatus?.heading}</h5>
            </div>
            <button
              className="top-close-btn"
              onClick={() => {
                setIsOpen(false);
                setSubmissionStatus(null);
              }}
            >
              <IoClose style={{ marginBottom: "-3px" }} />
            </button>
            <div className="modal-content">
              {submissionStatus?.link ? (
                <>
                  <p style={{ borderBottom: "2px solid gray", marginBottom: "1rem" }}>
                    {submissionStatus?.message}
                    <br />

                    <br />
                  </p>
                  <a
                    style={{ fontWeight: "bold", textDecoration: "none", fontStyle: "normal" }}
                    href={submissionStatus?.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click Here to Schedule!
                  </a>
                </>
              ) : (
                <p>{submissionStatus?.message}</p>
              )}
            </div>

            <div className="actions-container">
              <button
                className="close-btn"
                onClick={() => {
                  setIsOpen(false);
                  setSubmissionStatus(null);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
